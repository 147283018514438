import React, { useContext } from 'react'
import { Link, Routes, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import { Box, Input } from '@mui/material';
import { AuthContext } from '../../../context/AuthContext';
export default function Login() {
  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const {login} = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div>
      {/* create login inputs */}
      <Box sx={{display: 'flex',height:'100vh', flexDirection: 'column', justifyContent : 'center', alignItems :'center' }}>
        <Box sx={{ gap:2,display: 'flex', flexDirection: 'column',width :250 }}>
          <Input onChange={(e) => setUserName(e.target.value)} placeholder='Kullanıcı Adı' type='text'></Input>
          <Input onChange={(e) => setPassword(e.target.value)} placeholder='Şifre' type='password'></Input>
          <Button onClick={() => login(userName,password)} variant='outlined' size='small' >Login</Button>
          <Button onClick={() => navigate('/')} variant='outlined' size='small' >Ana Sayfa</Button>
        </Box>
      </Box>
    </div>
    )
}
