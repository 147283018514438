import React, { useState , useRef } from 'react';
import simsek from '../../../assets/simsek1.png';
import simsek2 from '../../../assets/simsek.jpg';
import '../taylan/style.css';
export default function Taylan() {
  const [yesClicked, setYesClicked] = useState(false);
  const [moveRandom, setMoveRandom] = useState({
    x: 0,
    y: 0,
  });
  const mainDiv = useRef<HTMLImageElement>(null);
  const noButton = useRef<HTMLButtonElement>(null);

  const handleMouseIn = (e : any) => {

  
    
    if (mainDiv.current != null) {
      
      var x = (Math.random() * (mainDiv.current.clientWidth)) * -1;
      var y = (Math.random() * (mainDiv.current.clientHeight)) * -1;

    setMoveRandom({
      x: x,
      y: y,
    });  
    }
    

    
  };

  const handleMouseOut = () => {
    setMoveRandom({
      x: 0,
      y: 0,
    });
  };

  const Yes = () => {
    setYesClicked(true);
  };

  return (
    <div style={{  backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          {yesClicked ? <h3 style={{ fontSize: 25 }} >KACHOWWWWWWW</h3> : <h3 style={{ fontSize: 25 }}>Benimle Date'e Çıkar mısın?</h3>}
        </div>
        
          <img  ref={mainDiv}  src={yesClicked ? simsek2 : simsek} width='50%' height='50%' alt="simsek" />
          <div style={{ display: yesClicked ? 'none' : 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' , justifyContent: 'center' }}>
            <button
              onClick={Yes}
              style={{
                marginTop: 20,
                width: 100,
                height: 50,
                backgroundColor: 'green',
                borderRadius: 20,
              }}
            >
              EVET
            </button>
            <button
             ref={noButton}
              onClick={(e => handleMouseIn(e))}
              onMouseEnter={(e) => handleMouseIn(e)}
              style={{
                top: moveRandom.y,
                left: moveRandom.x,
                marginTop: 20,
                cursor: 'pointer',
                position: 'relative',
                width: 100,
                height: 50,
                backgroundColor: 'red',
                borderRadius: 20,
                transition: 'top 0.1s, left 0.1s',
              }}
            >
              HAYIR
            </button>
          </div>
       
      </div>
    </div>
  );
}
