import React, { useContext } from 'react'
import { Button } from '@mui/material'
import { AuthContext } from '../../../context/AuthContext'


function Header() {
    const {userName, logout} = useContext(AuthContext)

    return (
    <div>
        <h1 style={{textAlign : 'center'}}>Hoşgeldin {userName}</h1>
        <div style={{display : 'flex', justifyContent : 'flex-end'}}>
            <Button onClick={() => logout()} sx={{margin : 1}} variant='outlined'>Çıkış Yap</Button>
        </div>
    </div>
  )
}

export default Header