import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './home'
import Login from './auth'
import Taylan from './taylan'

function PublicLayout() {

  return (
    <>
    <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/Login" element={<Login />} />
        <Route path='/Love' element={<Taylan />} />
    </Routes>
    </>
  )
}

export default PublicLayout