import { createContext } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

export const AuthContext = createContext(null as any)

export const AuthContextProvider = ({ children }: any) => {
    
    const navigate = useNavigate()
    const [isLogin , setIsLogin] = useState(false)
    const [userName, setUserName] = useState('')

    const login = (userName : String, password : String) => {
        if ((userName === 'Alper') && (password === '123456')) {
            setUserName('Alper');
            setIsLogin(true);
            navigate('/Dashboard')

        } else {
            setIsLogin(false)
        }
    }

    const logout = () => {
        setIsLogin(false)
        navigate('/Login')
    }

    return (
        <AuthContext.Provider value={{userName, isLogin, login, logout}}>
            {children}
        </AuthContext.Provider>
    )

}


export type AuthContextType = {
    isLogin: boolean,
    userName : string,
    login: (userName : String, password : String) => void
}