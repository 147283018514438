import logo from '../CryptoDetection/assets/btc.png';
import { useState, useEffect } from 'react';
import '../CryptoDetection/assets/style.css';

type DataType = {
  currency_pair: string,
  last: string,
  lowest_ask: string,
  highest_bid: string,
  change_percentage: string,
  change_utc0: string,
  change_utc8: string,
  base_volume: string,
  quote_volume: string,
  high_24h: string,
  low_24h: string,
  etf_net_value: string,
  etf_pre_net_value: string,
  etf_pre_timestamp: string,
  etf_leverage: string
};

function CryptoDetection() {

  const [cryptos, setCryptos] = useState<DataType[]>([]);
  const [interval, setInterVal] = useState(1);
  const [isStarted, setisStarted] = useState(false);
  const [intervalIds , setIntervalIds] = useState(1);
  const [firstVolumes, setFirstVolumes] = useState<any[]>([]);
  const [lastVolumes , setLastVolumes] = useState<any[]>([]);
  const [firstVolumeDate, setFirstVolumeDate] = useState<any>(null);
  const [topTen, setTopTen] = useState<any[]>([]);
  const [option , setOption] = useState<any>(5);
  const [search, setSearch] = useState<any>('');
  const [bigger, setBigger] = useState<any>('');
  var intervalId : any = null;

  const startSearch = (value : any) => {
    var searchedLastV = cryptos.filter((item: DataType) => item.currency_pair.match(new RegExp(value.toUpperCase(), 'i')));
    var searchedFirstV = firstVolumes.filter((item: DataType) => item.currency_pair.match(new RegExp(value.toUpperCase(), 'i')));
    var searchedCryptos = cryptos.filter((item: DataType) => item.currency_pair.match(new RegExp(value.toUpperCase(), 'i')));
    // var filterlastVolumes = lastVolumes.filter((item: DataType) => item.currency_pair.match(new RegExp(value, 'i')));
    // console.log(filterVolumes);
    // setLastVolumes(filterlastVolumes);
    // setFirstVolumes(filterVolumes);

    console.log(searchedFirstV, searchedLastV, searchedCryptos);
    setSearch(value);
    setCryptos([...searchedCryptos]);
    setFirstVolumes([...searchedFirstV]);
    setLastVolumes([...searchedLastV]);
  }

  useEffect(() => {
    if(isStarted) {
      startTracing(true);
    }
  }, [interval]);

  const requestGateİo = async () => {
    const requestOptions: any = {
      method: "GET"
    };
    fetch("https://api.gateio.ws/api/v4/spot/tickers", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let gelenData = JSON.parse(result);
        
        gelenData = gelenData.filter((item: DataType) => item.currency_pair.match(/([A-Z])\w+USDT/));
        gelenData.sort((a: DataType, b: DataType) => (a.currency_pair > b.currency_pair) ? 1 : -1);

        if (firstVolumes.length === 0) {
          setFirstVolumes(gelenData);
        } else {
          
          var lVolume = [];

          for (let index = 0; index < firstVolumes.length; index++) {
            lVolume.push({
              volume : ((gelenData[index].quote_volume) - (firstVolumes[index].quote_volume)),
              name : firstVolumes[index].currency_pair,
              change_percentage : firstVolumes[index].change_percentage
            });
          }         
          
          setLastVolumes(lVolume);
          getBigger(lVolume);
          setTopTen(lVolume.filter((item) => item.change_percentage > 0 && item.volume > 0)
          .sort((a, b) => {
            const changePercentageA = parseFloat(a.change_percentage);
            const changePercentageB = parseFloat(b.change_percentage);
            return changePercentageB - changePercentageA;
          })
          .slice(0, option));
        }
        setCryptos([]);
        setCryptos(gelenData);       
      })
      .catch((error) => console.error(error));
  }

  const startTracing  = async (onlyListAndSort: boolean) => {
    if (onlyListAndSort) {
      requestGateİo();
      setFirstVolumeDate(new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear() + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds());
      return;
    }

    setisStarted(true);
    
    if (intervalId === null) {
        intervalId = setInterval(() => {
          requestGateİo();
          setIntervalIds(intervalId);
         
      }, interval * 1000);
    }  
  };

  const stopTracing = () => {
    console.log(intervalIds);
    if (intervalIds !== null) {
      clearInterval(intervalIds)
      setisStarted(false);
    }
  };

  const getBigger = (list : any[] = lastVolumes) => {
    let biggerChange = list.filter((item) => item.change_percentage > 0 && item.volume > 0);
    let big = biggerChange.sort((a, b) => {
      return (b.volume - a.volume) 
    });
    setBigger(big[0].name + ' ' + big[0].volume + ' ' + big[0].change_percentage);
  }

  return (
    <div className="App">
      <div className='App-header'>
        <div style={{ backgroundColor: '#282c34' }}>
          <button disabled={isStarted} style={{ padding: 20, flex: 1, margin: 10, fontWeight: 'bold' }} onClick={() => startTracing(false)}>Takip Başlat</button>
          <button disabled={!isStarted} style={{ padding: 20, flex: 1, margin: 10, fontWeight: 'bold' }} onClick={() => stopTracing()}>Takip Durdur</button>
          <button style={{ padding: 20, flex: 1, margin: 10, fontWeight: 'bold' }} onClick={() => startTracing(true)}>Listele Ve Hacimleri Yakala</button>
          <label style={{ fontFamily: 'cursive', textAlign: 'center', alignSelf: 'center' }}>İstek Süresi(sn)</label>
          <input disabled={isStarted} min={1} onChange={(e) => setInterVal(parseInt(e.target.value))} style={{ padding: 20, flex: 1, margin: 10, fontWeight: 'bold' }} value={interval} id='traceTime' type='number'></input>
        </div>
        <img src={logo} className="App-logo" alt="logo" />
        <h1>İlk Hacim Tarihi {firstVolumeDate === null ? 'Yok' : firstVolumeDate }</h1>
        <div>
          <ul>
            <h1>En Yüksek 
              
                <select style={{ padding: 20, flex: 1, margin: 10, fontWeight: 'bold' }} onChange={(e) => setOption(e.target.value)}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="50">30</option>
                  <option value="50">50</option>
                  <option value="50">100</option>
                </select>
              
               Coin</h1>
            {topTen.map((e) => (
            
              <li key={e.name}>{e.name} / {e.volume} / {e.change_percentage}</li>
            ))}
          </ul>

          {/* <div style={{display : 'none'}}>
            <label>Ara</label>
          <input 
             style={{ padding: 20, flex: 1, margin: 10, fontWeight: 'bold' }}
             onChange={(e) => startSearch(e.target.value)}
             type='text' ></input></div> */}
        </div>
        <div>
          <label>Farka Göre En Yüksek İşlem Hacmi : <b>{bigger}</b></label>
        </div>
        <div style={{ display: 'flex', gap: 10, marginTop: 20 }}>
          <div style={{ background: 'black' , padding: 15 }}>
          <h4>Parite</h4>
            <ul>
              {cryptos.map((e) => (
                <li key={e.currency_pair}>{e.currency_pair}</li>
              ))}
            </ul>
          </div>
          <div style={{ background: 'black' , padding: 15 }}>
          <h4>Hacim</h4>
            <ul>
              {cryptos.map((e) => (
                <li key={e.currency_pair}>{Intl.NumberFormat('en').format(parseFloat(e.quote_volume))}</li>
              ))}
            </ul>
          </div>
          <div style={{ background: 'black' , padding: 15 }}>
          <h4>Fiyat</h4>
            <ul>
              {cryptos.map((e) => (
                <li key={e.currency_pair}>{Number.isInteger(e.last) ? Intl.NumberFormat('en').format(parseFloat(e.last)) : e.last}</li>
              ))}
            </ul>
          </div>
          <div style={{ background: 'black' , padding: 15 }}>
          <h4>Başlangıç Hacmi</h4>
            <ul>
              {firstVolumes.map((e) => (
                <li key={e.currency_pair}>{Intl.NumberFormat('en').format(parseFloat(e.quote_volume))}</li>
              ))}
            </ul>
          </div>
          <div style={{background: 'black' , padding: 15 }}>
            <h4>Fark (Oran)</h4>
            <ul >
              {lastVolumes.map((e) => (
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: e.change_percentage < 0 ? 'red' : e.change_percentage === 0 ? 'blue' : 'green' }}>
                  <li key={e.quote_volume}>
                    <span>{Intl.NumberFormat('en').format(parseFloat(e.volume)).toString() + ' ' + ' '}</span>
                    <span style={{ fontWeight: 'bold' }}>{(e.change_percentage > 0 ? '+' + e.change_percentage : e.change_percentage)}</span>
                  </li>
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CryptoDetection;
