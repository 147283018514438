import React from 'react'

function Projects() {
  return (
    <>
        <h1>Projects</h1>
    </>
  )
}

export default Projects