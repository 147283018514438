import React, { useContext } from 'react'
import { Routes } from 'react-router-dom'
import PublicLayout from './PublicLayout'
import PrivateLayout from './PrivateLayout'
import { AuthContext } from '../context/AuthContext'
export default function Views() {
  
  const {isLogin} = useContext(AuthContext)

  return (

    isLogin ? <PrivateLayout/> : <PublicLayout/>

    )
}
