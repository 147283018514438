import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Dashboard from './Dashboard'
import Projects from './Projects'
import Header from '../../components/layout-components/header'
import Footer from '../../components/layout-components/footer'
import CryptoDetection from './CryptoDetection'
function PrivateLayout() {
 
  return (
    <>
    <Header/>
      <Routes>
        <Route path='/Dashboard' element={<Dashboard />}></Route>
        <Route path='/Projects' element={<Projects />}></Route>
        <Route path='/CryptoDetection' element={<CryptoDetection />}></Route>
      </Routes>
    <Footer/>
    </>
  )
}

export default PrivateLayout