import React from 'react';
import './assets/home.css'; 
import BgParticles from '../../../components/Particles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChain,faPlusCircle,faBook,faCommentAlt , faDiagramProject} from '@fortawesome/free-solid-svg-icons';
import {  faLinkedin , faGithub } from '@fortawesome/free-brands-svg-icons';
import { ImageViewer } from "react-image-viewer-dv"
import { Link, useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  return (
    <div className="App">
      <div style={{zIndex : -1}}>
      <BgParticles></BgParticles>
      </div>
      <div className="container">
        <header className="header">
          <div className='service'>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div>
                <div>
                  <h1>Merhabalar Ben Alper Özmen</h1>
                </div>
                <div style={{ textAlign: 'start' }}>
                  <div style={{ display: 'block' }}>
                    <h5 style={{ display: 'inline-block', margin: 10 }}>Bana Ulaşın :</h5>
                    <div style={{ display: 'inline-block' }}>
                      <a href='mailto:alp3rozmen@gmail.com'><FontAwesomeIcon style={{ marginRight: 5 }} icon={faCommentAlt} /></a>
                      <a href='https://www.linkedin.com/in/alper-%C3%B6-360198213/'><FontAwesomeIcon style={{ marginRight: 5 }} icon={faLinkedin} /></a>
                      <a href='https://github.com/alp3rozmen'><FontAwesomeIcon style={{ marginRight: 5 }} icon={faGithub} /></a>
                    </div>
                  </div>
                </div>
              </div>
                <img onClick={() => navigate('/Login')} className="image-container profile-picture" src="https://avatars.githubusercontent.com/u/76844052?v=4" alt="Profile" />
            </div>
          </div>
          <div>
            <nav className="service">
            <ul className="nav-list">
              <li className="nav-item">
                <a href="#about" className="nav-link">Hakkımda <FontAwesomeIcon style={{ marginRight: 5 }} icon={faBook} /></a>
              </li>
              <li className="nav-item">
                <a href="#projects" className="nav-link">Projelerim <FontAwesomeIcon style={{ marginRight: 5 }} icon={faDiagramProject} /></a>
              </li>
              <li className="nav-item">
                <a href="#services" className="nav-link">Hizmetlerim <FontAwesomeIcon style={{ marginRight: 5 }} icon={faPlusCircle} /></a>
              </li>
              <li className="nav-item">
                <a href="#portfolio" className="nav-link">Referanslarım <FontAwesomeIcon style={{ marginRight: 5 }} icon={faChain} /></a>
              </li>
            </ul>
          </nav>
          </div>
        </header>

        <section id="about" className="section">
        <h2 className="service section-title">Hakkımda</h2>
        <p className="section-content">
          Merhaba, ben Alper. Yazılım geliştirme konusundaki tutkumla ve deneyimimle ön plana çıkan biriyim. 
          2 yıl süresince yazılım projeleri üzerinde çalışarak kendimi sürekli geliştirdim.
          Yeni teknolojileri öğrenmek ve projelerde uygulamak beni heyecanlandırıyor.
        </p>
        <p className="section-content">
          Android(Java) ve IOS için React Native, Web Tarafında CodeIgniter,Laravel,React teknolojilerini kullandım.
        </p>
        <p className="section-content">
          Ayrıca, ekip çalışmasını ve işbirliğini önemsiyorum. Birlikte çalıştığım projelerde, farklı yeteneklere sahip insanlarla bir araya gelerek 
          büyük başarılar elde ettik. İnovasyona ve sürekli öğrenmeye açık biriyim ve yeni projelerde yeni zorluklara karşı 
          kendimi geliştirmeye devam etmek istiyorum.
        </p>
      </section>


        <div >
          <div style={{ flex: 1 }}>
            <section id="projects" className="section">  
              <h2 style={{marginRight : 3}} className="service section-title">Projelerim</h2>
              <ul className="project-list">
                <li className="project-item">
                  <h3 className="project-title">Kahve Falı Uygulaması (Android)</h3>
                  <p className="project-description">Kullanıcılara kahve falı bakma deneyimi sunan bir Android uygulaması. Firebase kullanarak kullanıcıların fal bilgilerini depoladım.</p>
                </li>
                <li className="project-item">
                  <h3 className="project-title">Döviz Kurları Uygulaması (Android)</h3>
                  <p className="project-description">Canlı döviz kurlarını takip etmek için bir Android uygulaması. Firebase kullanarak güncel döviz verilerini alıp gösterdim.</p>
                </li>
                <li className="project-item">
                  <h3 className="project-title">Çevir Kazan Uygulaması (Android)</h3>
                  <p className="project-description">Kullanıcıların çeviri yaparak puan kazanabileceği ve kazandıkları puanlarla ödüller kazanabileceği bir Android uygulaması. Firebase kullanarak kullanıcı puanlarını ve ödül bilgilerini yönettim.</p>
                </li>
                <li className="project-item">
                  <h3 className="project-title">İddaa Tahmin Uygulaması (Android)</h3>
                  <p className="project-description">Kullanıcıların iddaa maçlarına tahmin yapabildiği ve skorlarını takip edebildiği bir Android uygulaması. Firebase kullanarak kullanıcı tahminlerini ve skorları depoladım.</p>
                </li>
              </ul>
            </section>
          </div>
        
            <section style={{display : 'block'}} id="skills" className="section">
              <h2 className="service section-title">Yeteneklerim</h2>
              <div className="skills-container">
                <div className="skill-item">
                  <h3 className="skill-title">Java</h3>
                  <div className="skill-bar">
                    <div className="skill-progress" style={{ width: '70%' }}></div>
                  </div>
                </div>
                <div className="skill-item">
                  <h3 className="skill-title">PHP</h3>
                  <div className="skill-bar">
                    <div className="skill-progress" style={{ width: '55%' }}></div>
                  </div>
                </div>
                <div className="skill-item">
                  <h3 className="skill-title">HTML</h3>
                  <div className="skill-bar">
                    <div className="skill-progress" style={{ width: '100%' }}></div>
                  </div>
                </div>
                <div className="skill-item">
                  <h3 className="skill-title">CSS</h3>
                  <div className="skill-bar">
                    <div className="skill-progress" style={{ width: '65%' }}></div>
                  </div>
                </div>
                <div className="skill-item">
                  <h3 className="skill-title">JavaScript</h3>
                  <div className="skill-bar">
                    <div className="skill-progress" style={{ width: '65%' }}></div>
                  </div>
                </div>
                <div className="skill-item">
                  <h3 className="skill-title">SQL</h3>
                  <div className="skill-bar">
                    <div className="skill-progress" style={{ width: '70%' }}></div>
                  </div>
                </div>

                <div className="skill-item">
                  <h3 className="skill-title">CodeIgniter</h3>
                  <div className="skill-bar">
                    <div className="skill-progress" style={{ width: '70%' }}></div>
                  </div>
                </div>

                <div className="skill-item">
                  <h3 className="skill-title">Laravel</h3>
                  <div className="skill-bar">
                    <div className="skill-progress" style={{ width: '70%' }}></div>
                  </div>
                </div>
                
              </div>
            </section>
          </div>
        

        <h2 id='portfolio' className="service">Referanslarım</h2>
       
          <div className='service' style={{display : 'flex', flexWrap : 'wrap', justifyContent : 'space-around'}}>
            <ImageViewer>
              <img width={250} alt='img' height={180} src='https://bgcp.bionluk.com/images/portfolio/1400x788/5efa341b-8fa3-494b-848e-5bb12cf2ff05.png'></img>
            </ImageViewer>

            <ImageViewer>
              <img width={250} alt='img' height={180} src='https://bgcp.bionluk.com/images/portfolio/1400x788/b5a6c7a0-2f73-49a7-af5a-ab49dcc10fff.png'></img>
            </ImageViewer>            
            
            <ImageViewer>
              <img width={250} alt='img' height={180} src='https://bgcp.bionluk.com/images/portfolio/1400x788/4b2c0136-5276-4669-b507-75043bf76e2e.png'></img>
            </ImageViewer>
            
            <ImageViewer>
              <img width={250} alt='img' height={180} src='https://berkaykaradeniz.netlify.app/assets/img/korumamonline.png'></img>
            </ImageViewer>
            
            <ImageViewer>
              <img width={250} alt='img' height={180}src='https://www.linkpicture.com/q/Ekran-goruntusu-2023-09-25-214254.png'></img>
            </ImageViewer>

            <ImageViewer>
              <img width={250}  alt='img' height={180}src='https://berkaykaradeniz.netlify.app/assets/img/ahsapazar.com.png'></img>
            </ImageViewer>
         
          </div>

          <section id="services" className="section">
            <h2 className="service section-title">Hizmetlerim</h2>
            <div className="service">
              <h3 className="service-title">Web Uygulama Geliştirme</h3>
              <p className="service-description">
                Özelleştirilmiş web uygulamaları tasarlayıp geliştirerek işletmenizin veya projenizin ihtiyaçlarına özel çözümler sunuyorum. 
                Kullanıcı dostu arayüzler ve güçlü backend sistemler ile projelerinizi hayata geçiriyoruz.
              </p>
            </div>
            <div className="service">
              <h3 className="service-title">Mobil Uygulama Geliştirme</h3>
              <p className="service-description">
                İOS ve Android için uyumlu mobil uygulamaların tasarımı ve geliştirilmesi konusunda uzmanım. 
                Mobil cihazlar için optimize edilmiş kullanıcı deneyimleri sunarak müşterilerinizle bağlantı kurmanıza yardımcı oluyorum.
              </p>
            </div>
            <div className="service">
              <h3 className="service-title">Web Tasarım</h3>
              <p className="service-description">
                Estetik açıdan çekici ve kullanıcı dostu web siteleri tasarlayarak markanızı çevrimiçi dünyada temsil ediyorum. 
                Modern tasarım ilkelerini ve en son teknolojileri kullanarak web siteleri oluşturuyorum.
              </p>
            </div>
          </section>

          <section id="experience" className="section">
            <h2 className="service">İş Deneyimlerim</h2>
            <div className="service">
              <h3 className="experience">Bizmed Yazılım Uzmanı</h3>
              <p className="experience-date">Mart 2022 - Devam Ediyor</p>
              <p className="experience-description">
                Orta Düzey Delphi Geliştiricisi olarak, masaüstü uygulamaların geliştirilmesi ve sürdürülmesine aktif katkı sağlarım. Yeteneklerimle Delphi programlama dilini kullanarak etkileyici ve kullanıcı dostu çözümler üretir ve mevcut projelere değer katarım. Ekibimle işbirliği yapar, programlama standartlarına uygun kodlama yapar ve sorunları çözme yeteneklerimi kullanarak verimli bir şekilde çalışırım.
              </p>
            </div>

          </section>
                  
        <footer className="service footer">
          <p className="footer-text">Telif Hakkı &copy; 2023 Alper ÖZMEN</p>
        </footer>
      </div>

      
    </div>
  );
}

export default Home;
