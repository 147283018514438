import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { AuthContext, AuthContextProvider } from './context/AuthContext';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
  <AuthContextProvider>
      <App />
  </AuthContextProvider>
  </BrowserRouter>
);

