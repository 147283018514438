import React from 'react'
import { Link } from 'react-router-dom'
function Dashboard() {
  return (
    <>
        <h1>Dashboard</h1>
        <ul>
            <li>
                <Link to={'/Projects'} style={{textDecoration: 'none'}}> Projeler </Link>
                <Link to={'/CryptoDetection'} style={{textDecoration: 'none'}}> Kripto Takip</Link>
            </li>
        </ul>
    </>
  )
}

export default Dashboard